.business-form {
	padding: 26px;
	width: 75%;
	margin: 25px auto 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	// box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
		rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	border-radius: 4px;

	.phone-input-group {
		.input-label {
			font-weight: 400;
			font-size: var(--input-label-size, var(--mantine-font-size-sm));
			word-wrap: break-word;
			display: inline-block;
		}
	}
}
