// .app_init-loader {
// 	display: block;
// }

.app-loader {
	min-height: calc(100vh - 60px);
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
