.categories-container {
	.actions-container {
		display: flex;
		align-items: center;
		gap: 35px;
		margin-left: 20px;
		margin-top: 20px;
	}

	.category-list {
		height: 100%;
		display: flex;
		margin: 0;
		align-items: center;
		gap: 35px;
	}

	.category-item {
		color: #7a7a7a;
		padding: 8px 24px;
		font-family: "Poppins", serif;
		font-size: 14px;
	}

	.active {
		background-color: #e4005b;
		color: #fff;
		padding: 8px 24px;
		border-radius: 15px;
		font-family: "Poppins", serif;
		font-size: 14px;
	}

	.new-category-buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
	}
}
