.login__page {
	min-height: 100vh;
	display: flex;

	.showcase {
		flex: 1.5;
		max-height: 100vh;
		background-image: url("../../assets/login-bg.png");
		background-position: center;
		background-size: cover;
		box-shadow: -5px 0px 10px 1px rgba(0, 0, 0, 0.4);
		// overflow: hidden;
	}

	// .showcase_section {
	// 	display: flex;
	// 	// border: 2px solid green;
	// 	height: 100%;
	// 	opacity: 0.8;
	// }

	// .pics_navigation {
	// 	display: flex;
	// 	gap: 20px;
	// 	flex-wrap: wrap;
	// 	max-height: 40vh;

	// 	img {
	// 		max-height: 180px;
	// 		border-radius: 15px;
	// 		background: center / contain no-repeat;
	// 	}
	// }

	.business_logo {
		text-align: center;
		font-weight: 400;
		font-size: 45px;
	}

	.heading {
		font-weight: 400;
		text-align: center;
		position: relative;
		bottom: 30px;
	}

	.content {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 40%;
	}

	.login__form {
		width: 65%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
	}

	.input_field-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 15px;
	}

	.forgot_password-link {
		font-size: 14px;
	}

	.signup_link_text {
		margin-top: 10px;
		font-size: 14px;
		align-self: flex-end;
	}

	.copyright_wrapper {
		position: absolute;
		bottom: 2px;
		align-self: center;
	}

	@media (max-width: 768px) {
		.showcase {
			display: none;
		}

		.content {
			width: 100%;
		}

		.login__form {
			width: 90%;
		}

		label,
		.forgot_password-link,
		.signup_link_text {
			font-size: 15px;
		}

		.signup_link_text a {
			font-weight: 600;
		}
	}
}
