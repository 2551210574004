.page-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #313131;
	padding: 15px 24px;
	min-height: 65px;

	.logo-container {
		height: 100%;
		display: flex;
		gap: 8px;
		align-items: center;

		h6 {
			margin: 0;
		}
	}

	.navigation {
		margin: 0;
		display: flex;
		align-items: center;
		gap: 15px;
	}

	.nav-item {
		color: inherit;
		text-decoration: none;
	}

	.active {
		color: #f50057;
	}
}
